// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-content-latest-works-akariapp-mdx": () => import("./../../../src/content/latest-works/akariapp.mdx" /* webpackChunkName: "component---src-content-latest-works-akariapp-mdx" */),
  "component---src-content-latest-works-akariweb-mdx": () => import("./../../../src/content/latest-works/akariweb.mdx" /* webpackChunkName: "component---src-content-latest-works-akariweb-mdx" */),
  "component---src-content-latest-works-ecommerce-mdx": () => import("./../../../src/content/latest-works/ecommerce.mdx" /* webpackChunkName: "component---src-content-latest-works-ecommerce-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */)
}

